/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import IconText from '../components/IconText'
import SummaryRow from '../components/SummaryRow'
import FreeTrialBanner from '../components/FreeTrialBanner'
import { getInvoicesRowData } from '../pageData/invoices_data'

const Invoices = () => {
  const { t } = useTranslation()
  const rowData = getInvoicesRowData(t) || []
  return (
    <PageLayout>
      <SEO title="Invoices" />
      <BannerWrapper>
        <BackButton />
        <Box p={[3, 3, 4, 4]}>
          <TextBlock
            heading={t('FeaturePage:featureInvoices:heading')}
            headingAsH1={true}
            textBeforeLink={t('FeaturePage:featureInvoices:detail')}
          />
        </Box>
        <Banner
          imageName="feature-woman-garden"
          imageAlt={t('ImageAlts:invoicesPageAlt1')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('InvoicesPage:firstBannerHeading')}
            textBeforeLink={t('InvoicesPage:firstBannerDetail')}
          />
        </Banner>
        <Banner
          imageName="tablet-screen-invoices-list"
          imageAlt={t('ImageAlts:invoicesPageAlt2')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('Global:howItWorks')} />
            <IconText text={t('InvoicesPage:listItem1')} />
            <IconText text={t('InvoicesPage:listItem2')} />
            <IconText text={t('InvoicesPage:listItem3')} />
            <IconText text={t('InvoicesPage:listItem4')} />
          </Box>
        </Banner>
        <SummaryRow summaryData={rowData} />
        <Banner
          imageName="detail-holding-plant"
          imageAlt={t('ImageAlts:invoicesPageAlt3')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('InvoicesPage:secondBannerHeading')}
            textBeforeLink={t('InvoicesPage:secondBannerDetail')}
          />
        </Banner>
      </BannerWrapper>
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default Invoices
